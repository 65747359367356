<template>
  <div class="list">
    <div class="row" v-for="(item, i) in list" :key="i">
      <div class="col">
        <span class="bold">A枪</span>
      </div>
      <div class="col">
        <div class="item bold">2019</div>
        <div class="item">
          <span>开始时间</span>
          <span class="bold">{{ item.start_time }}</span>
        </div>
        <div class="item">
          <span>结束时间</span>
          <span class="bold">{{ item.end_time }}</span>
        </div>
      </div>
      <div class="col">
        <div class="item">
          <span>时长</span>
          <span class="bold">12：00：00</span>
        </div>
        <div class="item">
          <span>电量</span>
          <span class="bold">1.00kwh</span>
        </div>
        <div class="item">
          <span>金额</span>
          <span class="bold">{{ item.order_money }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChargeRecord',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.list {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    background-color: #ccc;
  }
  .row {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    .col {
      .item {
        span {
          margin-right: 15px;
        }
      }
      .bold {
        color: #000;
      }
    }
  }
}
</style>
