<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- <div class="user">张三的电桩</div> -->
    <div class="content">
      <div class="left">
        <!-- 电桩 -->
        <div class="pile">
          <img src="@/assets/pile_big.png" alt="" />
          <div class="desc">
            <div class="status">
              <el-tag type="success" size="mini">{{ getPileStatus }}</el-tag>
            </div>
            <div class="row">
              <div class="col">
                <span>序列号</span>
                <span>{{ detail.pile_number }}</span>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col">
                <span>类型</span>
                <span>交流</span>
              </div>
              <div class="col">
                <span>枪数</span>
                <span v-if="detail.pile_gun">{{ detail.pile_gun.length || 0 }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span>电压</span>
                <span>220v</span>
              </div>
              <div class="col">
                <span>电流</span>
                <span>10A</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span>费率</span>
                <span>1.6</span>
              </div>
              <div class="col">
                <span>充电模式</span>
                <span>扫描</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 电枪 -->
        <div class="gun" v-for="(item, i) in detail.pile_gun" :key="i">
          <img src="@/assets/car.png" alt="" />
          <div class="gun-num">
            <div class="name">{{ item.name }}</div>
            <div class="num">
              <span>编号：</span>
              <span>{{ item.gun_number }}</span>
            </div>
            <div class="gun-status">
              <el-tag type="success" size="mini">{{ item.gun_status_dsc }}</el-tag>
            </div>
          </div>
          <div class="gun-user">
            <div class="row">
              <div class="col">
                <i class="el-icon-user-solid"></i>
                <span>充电用户</span>
              </div>
              <div class="col">张三</div>
            </div>
            <div class="row">
              <div class="col">
                <i class="el-icon-video-play"></i>
                <span>开始时间</span>
              </div>
              <div class="col">张三</div>
            </div>
            <div class="row">
              <div class="col">
                <i class="el-icon-time"></i>
                <span>持续时间</span>
              </div>
              <div class="col">张三</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tab-box">
          <span class="tab">
            <el-tabs v-model="activeName">
              <el-tab-pane label="充电记录" name="1"></el-tab-pane>
              <el-tab-pane label="故障统计" name="2"></el-tab-pane>
            </el-tabs>
          </span>
          <span class="date">
            <el-date-picker
              v-model="select_date"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              size="mini"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </span>
        </div>
        <div class="list">
          <ChargeRecord v-show="activeName == 1" :list="ChargeRecordList"></ChargeRecord>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dict_gun_status, dict_pile_status } from '@/enum/dict.js'
import { getDetailAPI, getChargeRecordAPI } from './api'
import ChargeRecord from './modules/charge-record.vue'

export default {
  name: 'Detail',
  components: { ChargeRecord },
  data() {
    return {
      activeName: '1',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      select_date: '',
      dict_gun_status,
      dict_pile_status,
      detail: {},
      ChargeRecordList: []
    }
  },

  mounted() {
    this.getPileChargeDetail()
  },
  computed: {
    getPileStatus() {
      const { status } = this.detail
      return this.dict_pile_status.find(item => item.value == status)?.name
    }
  },
  methods: {
    async getPileChargeDetail() {
      this.detail = await getDetailAPI(this.$route.params.id)
      this.getChargeRecord()
    },
    // 充电记录
    async getChargeRecord() {
      const { pile_number } = this.detail
      let params = { pile_number, pay_status: 1 }
      const res = await getChargeRecordAPI(params)
      this.ChargeRecordList = res.data || []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .user {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    background-color: #f0f2f5;
    padding: 10px 0;
  }
  .content {
    display: flex;
    justify-content: space-between;

    .left {
      min-width: 450px;
      flex: 1;
      border-right: 1px solid #ccc;
      .pile {
        display: flex;
        justify-content: space-around;
        img {
          display: block;
        }
        .desc {
          width: 300px;
          .status {
            color: red;
            font-size: 16px;
          }
          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;
            .col {
              display: flex;
              justify-content: space-between;
              flex: 1;
              &:first-child {
                margin-right: 100px;
              }
              span {
                white-space: nowrap;
                &:first-child {
                  margin-right: 20px;
                }
                &:last-child {
                  color: #000;
                }
              }
            }
          }
        }
      }
      .gun {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 80px;
        .img {
          display: block;
        }
        .gun-num {
          .name {
            font-weight: 600;
          }
          .num {
            margin: 6px 0;
          }
          .gun-status {
            color: red;
          }
        }
        .gun-user {
          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 6px 0;
            .col {
              margin-right: 20px;
              &:first-child {
                i {
                  margin-right: 5px;
                  font-size: 16px;
                }
              }
              &:last-child {
                color: #000;
              }
            }
          }
        }
      }
    }
    .right {
      flex: 1;
      min-width: 600px;
      padding: 0 20px;
      .tab-box {
        position: relative;
        .date {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}
</style>
